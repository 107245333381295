import { Box, Button, Flex } from '@chakra-ui/react';
import { Link } from 'gatsby';
import React from 'react';
import { FiShoppingCart } from 'react-icons/fi';

function CartButton({ count }) {
  return (
    <Box ml={8}>
      <Link to="/cart">
        <Button colorScheme="gray" size="sm">
          <FiShoppingCart />
          <Flex
            justifyContent="center"
            alignItems="center"
            p={1}
            color="gray.900"
            w={5}
            h={5}
            ml={3}
          >
            {count}
          </Flex>
        </Button>
      </Link>
    </Box>
  );
}

export default CartButton;
