import { Box, Button, Link as ExternalLink } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { IoArrowForward } from '@react-icons/all-files/io5/IoArrowForward';
import { IoArrowBack } from '@react-icons/all-files/io5/IoArrowBack';
import React from 'react';
import { Link } from 'gatsby';

const StyledLink = styled(Link)`
  position: relative;
  &:hover {
    &::after {
      transform: scaleX(1);
    }
  }
  ::after {
    content: '';
    transform-origin: left;
    position: absolute;
    bottom: -3px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: ${({ color }) =>
      color === 'secondary' ? '#ff6584' : '#38B2AC'};
    transform: scaleX(0);
    transition: 0.2s ease-out all;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  position: relative;
  &:hover {
    text-decoration: none;
    &::after {
      transform: scaleX(1);
    }
  }
  ::after {
    content: '';
    transform-origin: left;
    position: absolute;
    bottom: -3px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: ${({ color }) =>
      color === 'secondary' ? '#ff6584' : '#38B2AC'};
    transform: scaleX(0);
    transition: 0.2s ease-out all;
  }
`;

function LinkButton({
  text,
  ml = 0,
  mt = 0,
  link,
  marginY = 0,
  noUnderline = false,
  withIcon = true,
  iconPosition = 'right',
  color = 'secondary',
  external = false,
}) {
  const body = (
    <Button
      rightIcon={
        withIcon && iconPosition === 'right' ? <IoArrowForward /> : null
      }
      leftIcon={withIcon && iconPosition === 'left' ? <IoArrowBack /> : null}
      colorScheme={color === 'secondary' ? 'secondary' : 'primary'}
      variant="link"
      ml={ml}
      mt={mt}
      pb={1}
      my={marginY}
      _hover={{ textDecoration: 'none' }}
    >
      {text}
    </Button>
  );

  if (external) {
    return (
      <Box position="relative" display="inline">
        <StyledExternalLink href={link} color={color} target="_blank">
          {body}
        </StyledExternalLink>
      </Box>
    );
  }
  return noUnderline ? (
    <Box position="relative" display="inline">
      <Link to={`/${link}`}>{body}</Link>
    </Box>
  ) : (
    <Box position="relative" display="inline">
      <StyledLink to={`/${link}`} color={color}>
        {body}
      </StyledLink>
    </Box>
  );
}

export default LinkButton;
