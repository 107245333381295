import { Box, Flex, Text } from '@chakra-ui/react';
import { Link } from 'gatsby';
import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import LinkButton from '../buttons/link-button';

function AlliancesLink() {
  return (
    <>
      <LinkButton
        text="Learn about our new whole-school approach to managing anxiety"
        link="professionals/whole-school-anxiety-approach"
      />
    </>
  );
}

export default AlliancesLink;
