import { Box, Flex, UnorderedList } from '@chakra-ui/react';
import { Link } from 'gatsby';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import React from 'react';
import {
  aboutDropdown,
  professionalsDropdown,
  parentsDropdown,
} from '../../data/dropdown-menu-items';
import NavItem from './desktopNavItem';

function DesktopNav({ data }) {
  const image = getImage(data.logo.childImageSharp.gatsbyImageData);
  return (
    <div>
      <Flex
        w={{ base: '95%', lg: '100%' }}
        my={{ base: 4, md: 0 }}
        cursor="pointer"
        maxW="1280px"
        mx="auto"
        justifyContent="space-between"
        alignItems="center"
      >
        <Link to="/">
          <Box w="130px">
            <Img image={image} alt="parentshop logo" />
          </Box>
        </Link>
        <Flex justifyContent="center" alignItems="center">
          <UnorderedList
            display={{ base: 'none', lg: 'flex' }}
            alignItems="center"
            justifyContent="space-between"
            styleType="none"
          >
            <NavItem
              name="for professionals"
              dropdown={true}
              dropdownItems={professionalsDropdown}
            />
            <NavItem
              name="for parents"
              dropdown={true}
              dropdownItems={parentsDropdown}
            />
            <NavItem name="shop" />
            <NavItem
              name="about"
              dropdown={true}
              dropdownItems={aboutDropdown}
            />
            <NavItem name="contact" />
            {/* <NavItem name="calendar" /> */}
          </UnorderedList>
        </Flex>
      </Flex>
    </div>
  );
}

export default DesktopNav;
