import React, { createContext, useContext, useEffect, useState } from 'react';
import Client from 'shopify-buy';

const SHOPIFY_CHECKOUT_STORAGE_KEY = 'shopify_checkout_id';

const isBrowser = () => typeof window !== 'undefined';

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  domain: `parentshopaustralia.myshopify.com`,
});

const initialStoreState = {
  client,
  isAdding: false,
  checkout: { lineItems: [] },
};

export const StoreContext = createContext({
  store: initialStoreState,
  setStore: () => null,
});

function createNewCheckout(store) {
  return store.checkout.create();
}

function fetchCheckout(store, id) {
  return store.checkout.fetch(id);
}

function setCheckoutInState(checkout, setStore) {
  if (isBrowser()) {
    localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, checkout.id);
  }

  setStore((prevState) => ({ ...prevState, checkout }));
}

const StoreContextProvider = ({ children }) => {
  const [store, setStore] = useState(initialStoreState);

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for existing cart
      const existingCheckoutId = isBrowser()
        ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
        : null;
      if (existingCheckoutId) {
        try {
          const checkout = await fetchCheckout(client, existingCheckoutId);
          // Make sure cart has not already been purchased
          if (!checkout.completedAt) {
            setCheckoutInState(checkout, setStore);
            return;
          }
        } catch (error) {
          localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, null);
        }
      }

      const newCheckout = await createNewCheckout(client);
      setCheckoutInState(newCheckout, setStore);
    };
    initializeCheckout();
  }, []);

  return (
    <StoreContext.Provider value={{ store, setStore }}>
      {children}
    </StoreContext.Provider>
  );
};

function useStore() {
  const { store } = useContext(StoreContext);
  return store;
}

function useCartCount() {
  const {
    store: { checkout },
  } = useContext(StoreContext);
  const count = checkout.lineItems.reduce(
    (runningTotal, item) => item.quantity + runningTotal,
    0
  );
  return count;
}

function useCartTotals() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  const tax = checkout.totalTaxV2?.amount
    ? `$${Number(checkout.totalTaxV2.amount).toFixed(2)}`
    : '-';
  const total = checkout.totalTaxV2?.amount
    ? `$${Number(checkout.totalPriceV2.amount).toFixed(2)}`
    : '-';

  return {
    tax,
    total,
  };
}

function useCartItems() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  return checkout.lineItems;
}

function useAddItemToCart() {
  const {
    store: { checkout, client },
    setStore,
  } = useContext(StoreContext);

  async function addItemToCart(variantId, quantity) {
    if (!variantId || !quantity) {
      throw new Error('Both a size and a quantity are required');
    }

    setStore((prevState) => ({ ...prevState, isAdding: true }));

    const checkoutId = checkout.id;
    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];

    let newCheckout;
    try {
      newCheckout = await client.checkout.addLineItems(
        checkoutId,
        lineItemsToAdd
      );
    } catch (err) {
      throw new Error(err.message);
    }

    setStore((prevState) => ({
      ...prevState,
      isAdding: false,
      checkout: newCheckout,
    }));
  }

  return addItemToCart;
}

function useRemoveItemFromCart() {
  const {
    store: { checkout, client },
    setStore,
  } = useContext(StoreContext);

  async function removeItemFromCart(itemId) {
    const newCheckout = await client.checkout.removeLineItems(checkout.id, [
      itemId,
    ]);

    setStore((prevState) => ({ ...prevState, checkout: newCheckout }));
  }

  return removeItemFromCart;
}

function useUpdateCartItem() {
  const {
    store: { checkout, client },
    setStore,
  } = useContext(StoreContext);

  async function updateCartItem(itemsToUpdate) {
    const newCheckout = await client.checkout.updateLineItems(
      checkout.id,
      itemsToUpdate
    );
    setStore((prevState) => ({ ...prevState, checkout: newCheckout }));
  }

  return updateCartItem;
}

function useCheckout() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  return () => {
    window.open(checkout.webUrl);
  };
}

export {
  StoreContextProvider,
  useStore,
  useCartCount,
  useCartItems,
  useCartTotals,
  useAddItemToCart,
  useRemoveItemFromCart,
  useCheckout,
  useUpdateCartItem,
};
