import { chakra, Flex, useDisclosure, Link } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useCartCount } from '../../context/StoreContext';
import AlliancesLink from './aliances-link';
import CartButton from './cart-button';
import DesktopNav from './desktop-nav';
import MobileNav from './mobile-nav';
import OpenMenuButton from './open-menu-button';
import LinkButton from '../buttons/link-button';
import { getImage } from 'gatsby-plugin-image';

function Header() {
  const count = useCartCount();
  const { isOpen, onToggle } = useDisclosure();

  const handleOnClick = () => onToggle();

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "parentshop-logo-simple-small.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300)
        }
      }
    }
  `);


  return (
    <>
      <chakra.nav
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="start"
        boxShadow={{ base: 'lg', lg: 'none' }}
        position={{ base: 'fixed', lg: 'relative' }}
        w="100%"
        bg="gray.50"
        zIndex={{ base: '999', lg: 1 }}
      >
        <Flex
          display={{ base: 'none', lg: 'flex' }}
          borderBottom="1px solid"
          borderColor="gray.200"
          w={{ base: '90%', '2xl': '100%' }}
        >
          <Flex
            w={{ base: '95%', lg: '100vw' }}
            mx="auto"
            alignItems="center"
            justifyContent="space-between"
            py={2}
            maxW="1280px"
          >
            <AlliancesLink />
            <Flex alignItems="center">
              <LinkButton
                external={true}
                link="https://parentshop.thinkific.com/users/sign_in"
                text="Online course login"
                color="primary"
              />
              <CartButton count={count} />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
          w={{ base: '90%', '2xl': '100%' }}
          mx={{ base: 'auto', lg: '0' }}
          py={{ base: 1, md: 4, lg: 4 }}
          // borderBottom="1px solid"
          borderColor="gray.200"
        >
          <DesktopNav data={data} />
          <OpenMenuButton handleOnClick={handleOnClick} />
        </Flex>
      </chakra.nav>
      <MobileNav
        data={data}
        isOpen={isOpen}
        onToggle={onToggle}
        handleOnClick={handleOnClick}
      />
    </>
  );
}

export default Header;
