import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { encode } from '../../utils/encode';

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be at least 2 characters long.')
    .required('Required'),
  category: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  listId: Yup.string(),
});

function NewsletterForm() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  if (formSubmitted) {
    return (
      <Flex
        flexDir="column"
        alignItems="flex-end"
        w={{ base: '100%', xl: 'auto' }}
        minW={{ base: 'auto', xl: '500px' }}
      >
        <Flex
          flexDir="column"
          px={{ base: 8, md: 12 }}
          py={12}
          borderRadius="20px"
          bg="white"
          textAlign={{ base: 'center', md: 'left' }}
          w="100%"
          // maxW="500px"
          mx={{ base: 'auto', lg: '0' }}
          border="1px solid"
          borderColor="gray.200"
        >
          <Heading as="h4" fontSize="1.5rem" color="gray.800" fontWeight="600">
            Thanks for signing up to our newsletter!
          </Heading>
          <Text color="gray.600" mt={{ base: 4, lg: 4 }}>
            You'll be the first to know when we announce new courses or
            specials.
          </Text>
        </Flex>
      </Flex>
    );
  } else {
    return (
      <Flex
        flexDir="column"
        alignItems="flex-end"
        w={{ base: '100%', lg: 'auto' }}
        minW={{ base: 'auto', lg: '500px' }}
      >
        <Flex
          flexDir="column"
          px={{ base: 8, md: 12 }}
          py={12}
          borderRadius="20px"
          bg="white"
          textAlign="left"
          w="100%"
          mx={{ base: 'auto', lg: '0' }}
          border="1px solid"
          borderColor="gray.200"
        >
          <Heading as="h4" fontSize="1.5rem" color="gray.800" fontWeight="600">
            Join the Parentshop family!
          </Heading>
          <Text color="gray.600" mt={{ base: 4, lg: 4 }}>
            Subscribe to our mailing list and we'll let you know when we
            announce new courses or training material.
          </Text>
          <Formik
            initialValues={{
              name: '',
              email: '',
              category: '',
              listId: '',
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              let listId;
              switch (values.category) {
                case 'Child & Family Specialists':
                  listId = 'd3d8e289f9014e82f2dfe13d6dc71d6b';
                  break;
                case 'School Leaders & Teachers':
                  listId = '6e99d4b8b306cd23ce98152cad982b7e';
                  break;
                case 'Parents':
                  listId = '175249d190f80e95dace62b5b26b2218';
                  break;
                case 'Early Years Educators':
                  listId = 'd5712dc1efbcdac231ceafc59ce1f076';
                  break;
              }
              values.listId = listId;
              const data = encode({
                'form-name': 'mailingList',
                ...values,
              });
              axios
                .post('/', data, {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                })
                .then(() => {
                  setFormSubmitted(true);
                })
                .catch((err) => console.error(err.message));
            }}
          >
            {({ isSubmitting, handleSubmit, dirty, isValid }) => (
              <Form
                name="mailingList"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="mailingList" />
                <Flex
                  flexDir="column"
                  justifyContent="center"
                  alignItems={{ base: 'start', lg: 'center' }}
                  mt={4}
                >
                  <Flex w="100%" justifyContent="center" alignItems="center">
                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl
                          id="name"
                          mr={2}
                          isInvalid={form.errors.name && form.touched.name}
                        >
                          <FormLabel>Name</FormLabel>
                          <Input
                            {...field}
                            placeholder="Name"
                            type="text"
                            name="name"
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                  <Flex
                    w="100%"
                    justifyContent="center"
                    alignItems="center"
                    mt={3}
                  >
                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          id="email"
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <FormLabel>Email</FormLabel>
                          <Input
                            {...field}
                            placeholder="Email address"
                            type="email"
                            name="email"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                  <Field name="listId">
                    {({ field, form }) => (
                      <Input {...field} type="hidden" name="listId" />
                    )}
                  </Field>
                  <Flex
                    flexDir={{ base: 'column', lg: 'row' }}
                    w="100%"
                    mt={3}
                    alignItems={{ base: 'flex-start', lg: 'flex-end' }}
                  >
                    <Field name="category">
                      {({ field, form }) => (
                        <FormControl
                          id="category"
                          mr={2}
                          isInvalid={
                            form.errors.category && form.touched.category
                          }
                        >
                          <FormLabel>Category</FormLabel>
                          <Select
                            placeholder="Select newsletter category"
                            cursor="pointer"
                            {...field}
                          >
                            <option>School Leaders & Teachers</option>
                            <option>Child & Family Specialists</option>
                            <option>Early Years Educators</option>
                            <option>Parents</option>
                          </Select>
                          <FormErrorMessage>
                            {form.errors.category}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Button
                      isLoading={isSubmitting}
                      isDisabled={!(dirty && isValid)}
                      colorScheme="secondary"
                      type="submit"
                      mt={{ base: 4, lg: 0 }}
                      w={{ base: '100%', lg: '150px' }}
                    >
                      Subscribe
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    );
  }
}

export default NewsletterForm;
