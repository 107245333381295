import { Accordion, Box, chakra, Flex, Text } from '@chakra-ui/react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { getImage, GatsbyImage as Image } from 'gatsby-plugin-image';
import React from 'react';
import { IconContext } from 'react-icons';
import { FiFacebook, FiInstagram, FiLinkedin } from 'react-icons/fi';
import FooterAccordionList from './footerAccordionListItem';
import FooterNavColumn from './footerNavColumn';
import NewsletterForm from './newsletter-form';

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "parentshop-logo-simple.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
    }
  `);
  data;

  return (
    <chakra.footer py={{ base: 8, md: 12, lg: 16 }} bg="gray.100" w="100%">
      <Flex flexDir="column" width="90%" maxW="1280px" mx="auto">
        <Flex
          justifyContent="space-between"
          alignItems={{ base: 'center', xl: 'start' }}
          flexDir={{ base: 'column', xl: 'row' }}
        >
          {/* Desktop Sitemap */}
          <Flex
            mb={{ base: 16, lg: 0 }}
            display={{ base: 'none', xl: 'flex' }}
            w="100%"
          >
            <FooterNavColumn
              heading="Courses"
              navItems={[
                {
                  name: 'Primary school leaders & teachers',
                  link: 'professionals/primary-education',
                },
                {
                  name: 'Secondary school leaders & teachers',
                  link: 'professionals/secondary-education',
                },
                {
                  name: 'Early years educators',
                  link: 'professionals/early-years',
                },
                {
                  name: 'Businesses & organisations',
                  link: 'professionals/businesses-organisations',
                },
                {
                  name: 'Child & family specialists',
                  link: 'professionals/child-family-specialists',
                },
                { name: 'For parents', link: 'for-parents' },
              ]}
            />
            <FooterNavColumn
              heading="Shop"
              navItems={[
                { name: 'Online courses', link: 'shop' },
                { name: 'Books & resources', link: 'shop' },
                { name: 'Workbooks', link: 'shop' },
                { name: 'Practitioner materials order form', link: 'shop' },
                { name: 'FAQs', link: 'shop/faqs' },
              ]}
            />
            <FooterNavColumn
              heading="About"
              navItems={[
                { name: 'About Parentshop', link: 'about/parentshop' },
                { name: 'About Michael Hawton', link: 'about/michael-hawton' },
                { name: 'Meet the team', link: 'about/meet-the-team' },
                { name: 'Privacy policy', link: 'privacy-policy' },
                { name: 'Terms & conditions', link: 'terms-and-conditions' },
                { name: 'Complaints handling', link: 'complaints-handling' },
              ]}
            />
          </Flex>
          {/* Mobile Sitemap */}

          <Accordion
            allowToggle
            w="100%"
            mb={{ base: 12, lg: 16 }}
            display={{ base: 'block', xl: 'none' }}
          >
            <FooterAccordionList
              buttonText="Courses"
              listItems={[
                {
                  text: 'Primary school leaders & teachers',
                  path: 'professionals/primary-education',
                },
                {
                  text: 'Secondary school leaders & teachers',
                  path: 'professionals/secondary-education',
                },
                {
                  text: 'Early years educators',
                  path: 'professionals/early-years',
                },
                {
                  text: 'Child & family specialists',
                  path: 'professionals/child-family-specialists',
                },
                { text: 'Parents', path: 'for-parents' },
              ]}
            />
            <FooterAccordionList
              buttonText="Shop"
              listItems={[
                { text: 'Online courses', path: 'shop' },
                { text: 'Books & resources', path: 'shop' },
                { text: 'Workbooks', path: 'shop' },
                { text: 'Practitioner materials order form', path: 'shop' },
                { text: 'FAQs', path: 'shop/faqs' },
              ]}
            />
            <FooterAccordionList
              buttonText="About"
              listItems={[
                { text: 'About Parentshop', path: 'about/parentshop' },
                { text: 'About Michael Hawton', path: 'about/michael-hawton' },
                { text: 'Meet the team', path: 'about/meet-the-team' },
                { text: 'Privacy policy', path: 'privacy-policy' },
                { text: 'Terms & conditions', path: 'terms-and-conditions' },
                { text: 'Complaints handling', path: 'complaints-handling' },
                // { text: 'Privacy policy', path: 'about/privacy-policy' },
                // {
                //   text: 'Terms & conditions',
                //   path: 'about/terms-and-conditions',
                // },
                // {
                //   text: 'Complaints handling',
                //   path: 'about/complaints-handling',
                // },
              ]}
            />
          </Accordion>
          <NewsletterForm />
          <Flex
            my={8}
            w="100%"
            justifyContent={{ base: 'center', xl: 'flex-start' }}
            display={{ base: 'flex', xl: 'none' }}
          >
            <Link to="/">
              <Image
                image={getImage(data.logo.childImageSharp.gatsbyImageData)}
                alt="parentshop logo"
              />
            </Link>
          </Flex>
        </Flex>
        <Flex
          justifyContent="center"
          alignItems="center"
          mt={{ base: 12, lg: 24 }}
        >
          <Flex flexDir="column" alignItems="center" color="primary.500">
            <Flex>
              <chakra.a
                px={4}
                href="https://www.facebook.com/Parentshop/"
                target="_blank"
              >
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <Box>
                    <FiFacebook />
                  </Box>
                </IconContext.Provider>
              </chakra.a>
              <chakra.a
                px={4}
                href="https://www.instagram.com/parentshopparents/"
                target="_blank"
              >
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <Box>
                    <FiInstagram />
                  </Box>
                </IconContext.Provider>
              </chakra.a>
              <chakra.a
                px={4}
                href="https://twitter.com/parentshop?lang=en"
                target="_blank"
              >
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <Box>
                    <FiLinkedin />
                  </Box>
                </IconContext.Provider>
              </chakra.a>
            </Flex>
            <Flex
              flexDir="column"
              color="gray.600"
              alignItems="center"
              fontSize="sm"
              mt={4}
            >
              <Text>&copy; Parentshop {new Date().getFullYear()}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </chakra.footer>
  );
}

export default Footer;
