export const aboutDropdown = [
  {
    name: 'Parentshop',
    description: 'Learn more about Parentshop.',
    path: 'about/parentshop',
  },
  {
    name: 'Michael Hawton',
    description: 'Find out more about our founder.',
    path: 'about/michael-hawton',
  },
  {
    name: 'Meet the team',
    description: 'Get to know us.',
    path: 'about/meet-the-team',
  },
  {
    name: 'Join the team',
    description: 'See our open positions.',
    path: 'about/join-the-team',
  },
  {
    name: 'Media',
    description: 'Parentshop in the media.',
    path: 'about/media',
  },
  {
    name: 'Blog',
    description: 'See the latest.',
    path: 'about/blog',
  },
];

export const parentsDropdown = [
  {
    name: 'Courses',
    description: 'For parents & carers.',
    path: 'for-parents',
  },
  {
    name: 'Find a parent eductor',
    description: 'Trained educators in your area.',
    path: 'parents/find-parent-educator',
  },
];

export const professionalsDropdown = [
  {
    name: 'Primary teachers & leaders',
    description: 'Courses for primary-school educators.',
    path: 'professionals/primary-education',
  },
  {
    name: 'Secondary teachers & leaders',
    description: 'Courses for secondary-school educators.',
    path: 'professionals/secondary-education',
  },
  {
    name: 'Child & family specialists',
    description: 'Courses for child & family specialists.',
    path: 'professionals/child-family-specialists',
  },
  {
    name: 'Early years educators',
    description: 'Courses for early years educators & directors.',
    path: 'professionals/early-years',
  },
  {
    name: 'In-house training',
    description: 'Training a group? Let us come to you.',
    path: 'professionals/in-house-training',
  },
  {
    name: 'Whole-school approach to managing anxiety',
    description: 'Learn about out whole-school anxiety initiative.',
    path: 'professionals/whole-school-anxiety-approach',
  },
  {
    name: 'Training taster series',
    description: 'Get to know our training taster series.',
    path: 'professionals/training-taster-series',
  },
];
