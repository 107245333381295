import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1440px',
});

export default extendTheme({
  colors: {
    primary: {
      100: '#EAF7F7',
      200: '#C1E8E6',
      300: '#98D9D5',
      400: '#46BBB3',
      500: '#38B2AC',
      600: '#278F89',
      700: '#1D6B67',
      800: '#195A56',
      900: '#0F3633',
    },
    secondary: {
      100: '#FFECF0',
      200: '#ffc1ce',
      300: '#ffa3b5',
      400: '#ff849d',
      500: '#FF6584',
      600: '#cc516a',
      700: '#993d4f',
      800: '#662835',
      900: '#33141a',
    },
  },
  fonts: {
    body: 'Proxima Nova, system-ui, sans-serif',
    heading: 'Proxima Nova, system-ui, sans-serif',
  },
  breakpoints,
});
