import { chakra } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import React from 'react';

function OpenMenuButton({ handleOnClick }) {
  return (
    <chakra.button
      display={{ base: 'flex', lg: 'none' }}
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      w={9}
      h={9}
      border="1px solid"
      borderRadius="8px"
      borderColor="gray.200"
      onClick={handleOnClick}
    >
      <HamburgerIcon />
    </chakra.button>
  );
}

export default OpenMenuButton;
