exports.components = {
  "component---src-pages-1-2-3-magic-research-references-tsx": () => import("./../../../src/pages/1-2-3-magic-research-references.tsx" /* webpackChunkName: "component---src-pages-1-2-3-magic-research-references-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-blog-tsx": () => import("./../../../src/pages/about/blog.tsx" /* webpackChunkName: "component---src-pages-about-blog-tsx" */),
  "component---src-pages-about-join-the-team-tsx": () => import("./../../../src/pages/about/join-the-team.tsx" /* webpackChunkName: "component---src-pages-about-join-the-team-tsx" */),
  "component---src-pages-about-media-tsx": () => import("./../../../src/pages/about/media.tsx" /* webpackChunkName: "component---src-pages-about-media-tsx" */),
  "component---src-pages-about-meet-the-team-tsx": () => import("./../../../src/pages/about/meet-the-team.tsx" /* webpackChunkName: "component---src-pages-about-meet-the-team-tsx" */),
  "component---src-pages-about-michael-hawton-tsx": () => import("./../../../src/pages/about/michael-hawton.tsx" /* webpackChunkName: "component---src-pages-about-michael-hawton-tsx" */),
  "component---src-pages-about-parentshop-tsx": () => import("./../../../src/pages/about/parentshop.tsx" /* webpackChunkName: "component---src-pages-about-parentshop-tsx" */),
  "component---src-pages-calendar-tsx": () => import("./../../../src/pages/calendar.tsx" /* webpackChunkName: "component---src-pages-calendar-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-for-parents-tsx": () => import("./../../../src/pages/for-parents.tsx" /* webpackChunkName: "component---src-pages-for-parents-tsx" */),
  "component---src-pages-for-professionals-tsx": () => import("./../../../src/pages/for-professionals.tsx" /* webpackChunkName: "component---src-pages-for-professionals-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-parents-find-parent-educator-tsx": () => import("./../../../src/pages/parents/find-parent-educator.tsx" /* webpackChunkName: "component---src-pages-parents-find-parent-educator-tsx" */),
  "component---src-pages-professionals-businesses-organisations-tsx": () => import("./../../../src/pages/professionals/businesses-organisations.tsx" /* webpackChunkName: "component---src-pages-professionals-businesses-organisations-tsx" */),
  "component---src-pages-professionals-child-family-specialists-tsx": () => import("./../../../src/pages/professionals/child-family-specialists.tsx" /* webpackChunkName: "component---src-pages-professionals-child-family-specialists-tsx" */),
  "component---src-pages-professionals-early-years-tsx": () => import("./../../../src/pages/professionals/early-years.tsx" /* webpackChunkName: "component---src-pages-professionals-early-years-tsx" */),
  "component---src-pages-professionals-in-house-training-tsx": () => import("./../../../src/pages/professionals/in-house-training.tsx" /* webpackChunkName: "component---src-pages-professionals-in-house-training-tsx" */),
  "component---src-pages-professionals-primary-education-tsx": () => import("./../../../src/pages/professionals/primary-education.tsx" /* webpackChunkName: "component---src-pages-professionals-primary-education-tsx" */),
  "component---src-pages-professionals-secondary-education-tsx": () => import("./../../../src/pages/professionals/secondary-education.tsx" /* webpackChunkName: "component---src-pages-professionals-secondary-education-tsx" */),
  "component---src-pages-professionals-whole-school-anxiety-approach-tsx": () => import("./../../../src/pages/professionals/whole-school-anxiety-approach.tsx" /* webpackChunkName: "component---src-pages-professionals-whole-school-anxiety-approach-tsx" */),
  "component---src-pages-shop-faqs-tsx": () => import("./../../../src/pages/shop/faqs.tsx" /* webpackChunkName: "component---src-pages-shop-faqs-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-course-page-tsx": () => import("./../../../src/templates/course-page.tsx" /* webpackChunkName: "component---src-templates-course-page-tsx" */),
  "component---src-templates-fine-print-tsx": () => import("./../../../src/templates/fine-print.tsx" /* webpackChunkName: "component---src-templates-fine-print-tsx" */),
  "component---src-templates-print-media-page-tsx": () => import("./../../../src/templates/print-media-page.tsx" /* webpackChunkName: "component---src-templates-print-media-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */)
}

