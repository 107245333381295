import { Flex } from '@chakra-ui/react';
import Helmet from 'react-helmet';
import React from 'react';
import Footer from '../footer/footer';
import Header from '../header/header';
import '../../styles/global.css';
import CookieConsent from 'react-cookie-consent';

function Layout({ children }) {
  return (
    <Flex minH="100vh" flexDir="column" bgColor="gray.50">
      <Flex
        flexDir="column"
        maxW="1280px"
        mx="auto"
        flexGrow={1}
        color="gray.800"
        w="100%"
      >
        <Flex flexDir="column" w="100%">
          <Header />
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            cookieName="myAwesomeCookieName2"
            style={{ background: '#EDF2F7', color: '#000' }}
            buttonStyle={{
              color: '#fff',
              background: '#FF6584',
              fontWeight: '600',
              borderRadius: '5px',
            }}
            enableDeclineButton={true}
            declineButtonStyle={{
              background: '#fff',
              color: '#FF6584',
              fontWeight: '600',
              borderRadius: '5px',
            }}
            // expires={150}
          >
            This website uses cookies to enhance the user experience.
          </CookieConsent>
          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent="start"
            w={{ base: '90%', '2xl': '100%' }}
            pt={{ base: '100px', md: '110px', lg: '0' }}
            minH="100vh"
            mx="auto"
          >
            {children}
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </Flex>
  );
}

export default Layout;
