import { CloseIcon } from '@chakra-ui/icons';
import {
  Fade,
  Box,
  Flex,
  UnorderedList,
  ListItem,
  chakra,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Link as ExternalLink,
} from '@chakra-ui/react';
import { Link } from 'gatsby';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import React from 'react';
import MobileNavItem from './mobileNavItem';
import {
  aboutDropdown,
  parentsDropdown,
  professionalsDropdown,
} from '../../data/dropdown-menu-items';

function MobileNav({ isOpen, data, onToggle, handleOnClick }) {
  return (
    <Box
      position="fixed"
      bgColor="#00000050"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="999"
      display={{ base: isOpen ? 'visible' : 'none', lg: 'none' }}
    >
      <Flex
        display={{ base: isOpen ? 'visible' : 'none', lg: 'none' }}
        alignItems="center"
        justifyContent="space-between"
        flexDir="column"
        position="absolute"
        top="0"
        left="0"
        right="0"
        bgColor="white"
        boxShadow="lg"
        py={{ base: 3, md: 6 }}
        m={3}
        borderRadius="lg"
        textAlign="center"
      >
        <UnorderedList styleType="none" ml={0} w="100%" px={6} pb={6}>
          <ListItem mb={6}>
            <Flex my={{ base: 4, md: 0 }} cursor="pointer">
              <Link to="/">
                <Box w="125px">
                  <Img
                    image={getImage(data.logo.childImageSharp.gatsbyImageData)}
                    alt="parentshop logo"
                  />
                </Box>
              </Link>
            </Flex>
          </ListItem>

          <Accordion allowToggle>
            <AccordionItem border="none">
              <AccordionButton pl={0}>
                <Box textAlign="left" fontWeight="600" color="gray.800">
                  Professionals
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel w="100%" ml={0} pl={0}>
                <UnorderedList styleType="none" ml={0} textAlign="left">
                  {professionalsDropdown.map((item, index) => (
                    <ListItem
                      my={4}
                      ml={0}
                      color="gray.800"
                      key={index}
                      fontWeight="500"
                      onClick={() => onToggle()}
                    >
                      <Link to={`/${item.path}`}>{item.name}</Link>
                    </ListItem>
                  ))}
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Accordion allowToggle my={6}>
            <AccordionItem border="none">
              <AccordionButton pl={0}>
                <Box textAlign="left" fontWeight="600" color="gray.800">
                  Parents
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel w="100%" ml={0} pl={0}>
                <UnorderedList styleType="none" ml={0} textAlign="left">
                  {parentsDropdown.map((item, index) => (
                    <ListItem
                      my={4}
                      ml={0}
                      color="gray.800"
                      key={index}
                      fontWeight="500"
                      onClick={() => onToggle()}
                    >
                      <Link to={`/${item.path}`}>{item.name}</Link>
                    </ListItem>
                  ))}
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <MobileNavItem
            isOpen={isOpen}
            onToggle={onToggle}
            name="shop"
            slug="shop"
          />
          <Accordion allowToggle>
            <AccordionItem border="none">
              <AccordionButton pl={0}>
                <Box textAlign="left" fontWeight="600" color="gray.800">
                  About
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel w="100%" ml={0} pl={0}>
                <UnorderedList styleType="none" ml={0} textAlign="left">
                  {aboutDropdown.map((item, index) => (
                    <ListItem
                      my={4}
                      ml={0}
                      color="gray.800"
                      key={index}
                      fontWeight="500"
                      onClick={() => onToggle()}
                    >
                      <Link to={`/${item.path}`}>{item.name}</Link>
                    </ListItem>
                  ))}
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <MobileNavItem
            isOpen={isOpen}
            onToggle={onToggle}
            name="contact"
            slug="contact"
          />
          {/* <MobileNavItem
            isOpen={isOpen}
            onToggle={onToggle}
            name="calendar"
            slug="calendar"
          /> */}
          <Box borderTop="1px solid" borderColor="gray.200" />
          <Flex>
            <ExternalLink
              mt={5}
              href="https://parentshop.thinkific.com/users/sign_in"
              target="_blank"
              color="primary.500"
              fontWeight="600"
            >
              Online course login
            </ExternalLink>
          </Flex>
          <MobileNavItem
            isOpen={isOpen}
            onToggle={onToggle}
            name="cart"
            slug="cart"
          />
        </UnorderedList>
        <chakra.button
          display={{ base: 'flex', lg: 'none' }}
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          onClick={handleOnClick}
          position="absolute"
          right="0"
          top="0"
          mr={5}
          mt={5}
          w={9}
          h={9}
        >
          <CloseIcon />
        </chakra.button>
      </Flex>
    </Box>
  );
}

export default MobileNav;
